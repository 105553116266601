import axiosClient from '../utils/axiosClient'
import { iAssistant } from '../interfaces/iAssistantTypes.ts'
import { iTool } from '@/interfaces/iTool.ts'
import { iKnowledgeContainer } from '@/interfaces/iKnowledgeContainer.ts'
import { AccessRight } from '@/interfaces/iShared.ts'
import { iMessage } from '@/interfaces/iMessage.ts'

class AssistantService {
  private static instance: AssistantService

  private constructor() {}

  public static getInstance(): AssistantService {
    if (!AssistantService.instance) {
      AssistantService.instance = new AssistantService()
    }

    return AssistantService.instance
  }

  public async createAssistant(assistant: iAssistant): Promise<iAssistant> {
    return (await axiosClient.post('/assistants', assistant)).data as iAssistant
  }

  public async deleteAssistant(assistantId: string): Promise<void> {
    const response = await axiosClient.delete(`/assistants/${assistantId}`)
    if (response.status !== 200) {
      throw new Error('Could not delete system prompt')
    }
  }

  public async getAssistant(assistantId: string): Promise<iAssistant> {
    return (await axiosClient.get(`/assistants/${assistantId}`)).data as iAssistant
  }

  public async getAssistantTools(assistantId: string): Promise<iTool[]> {
    return (await axiosClient.get(`/assistants/${assistantId}/tools`))
      .data as iTool[]
  }

  public async getAssistantKnowledgeContainer(
    assistantId: string,
  ): Promise<iKnowledgeContainer[]> {
    return (await axiosClient.get(`/assistants/${assistantId}/knowledge-containers`))
      .data as iKnowledgeContainer[]
  }

  public async getAssistants(filter?: {
    accessRight?: AccessRight
    searchValue?: string
    limit?: number
    offset?: number
  }): Promise<{ data: iAssistant[]; total: number }> {
    return (
      await axiosClient.get('/assistants', {
        params: {
          accessRight: filter?.accessRight,
          limit: filter?.limit,
          offset: filter?.offset,
          searchValue: filter?.searchValue,
        },
      })
    ).data as {
      data: iAssistant[]
      total: number
    }
  }

  public async updateAssistant(assistant: iAssistant): Promise<iAssistant> {
    const response = await axiosClient.put(`/assistants/${assistant.id}`, assistant)
    if (response.status !== 200) {
      throw new Error('Could not update system prompt')
    }
    return response.data
  }

  public async createAssistantIcon(assistantId: string): Promise<string> {
    const response = await axiosClient.post(`/assistants/${assistantId}/icons`)
    if (response.status !== 200) {
      throw new Error('Could not create assistant icon')
    }
    return response.data as string
  }

  public async deleteAssistantIcon(
    assistantId: string,
    iconId: string,
  ): Promise<void> {
    const response = await axiosClient.delete(
      `/assistants/${assistantId}/icons/${iconId}`,
    )
    if (response.status !== 200 && response.status !== 204) {
      throw new Error('Could not delete assistant icon')
    }
  }

  public async getAssistantIconUrl(
    assistantId: string,
    iconId: string,
  ): Promise<string> {
    const response = await axiosClient.get(
      `/assistants/${assistantId}/icons/${iconId}`,
    )
    if (response.status !== 200) {
      throw new Error('Could not get assistant icon URL')
    }
    return response.data as string
  }

  public async runAssistantChatCompletion(
    assistantId: string,
    prompt: string,
  ): Promise<iMessage> {
    return (
      await axiosClient.post(`/assistants/${assistantId}/chat/completions`, {
        prompt,
      })
    ).data as iMessage
  }
}

export default AssistantService.getInstance()
